<template>
  <div class="tw-payment-form-element__price flex items-center justify-between">
    <span :style="textStyle" class="tw-payment-form-element__price-label flex-1">
      {{ translate('generate.label.total', locale) }}:
    </span>
    <span
      :style="textStyle"
      class="tw-payment-form-element__price-info"
    >{{ totalPrice }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useTranslate } from '@shared/composable/useTranslate';
import type { PageOptions } from '@shared/types/model';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
  totalPrice: { type: String, default: '' },
  labelStyle: { type: Object, default: () => ({}) },
});

const { translate } = useTranslate();

const textStyle = computed(() => {
  const color = props.labelStyle.totalLineColor || props.pageOptions.colors?.text[0];
  const fontFamily = props.pageOptions.fontFamily;
  return {
    color,
    fontFamily
  };
});
</script>

<style lang="postcss" scoped>
.tw-payment-form-element {
  &__price {
    &-label, &-info {
      font-size: 18px;
      font-weight: 600 !important; /* Price info must be bold always */
    }
  }
}
</style>
